import React from "react";
import styled from "styled-components";
import CookieBanner from "../components/CookieBanner";
import Footer from "../components/Footer";
import LayoutSection from "../components/layout/LayoutSection";
import LayoutWrapper from "../components/layout/LayoutWrapper";
import SEO from "../components/seo";
import SiteHeader from "../components/SiteHeader";
import H3 from "../components/typography/H3";
import P2 from "../components/typography/P2";
import colors from "../components/style/colors";
import "../main.css";

const StyledUl = styled.ul`
  font-size: 1.12em;
  text-align: left;
  color: ${colors.dark};
  margin: 1em 0 1em;
  font-weight: 500;
  line-height: 2.5;
`;

const LifetimeDownloadPage = props => {
  return (
    <>
      <CookieBanner />
      <LayoutWrapper>
        <SEO title="" canonical={props.location.href} />

        <SiteHeader dark />
        <LayoutSection small>
          <H3>I've purchased a Boxy Suite lifetime license, how do I download the apps?</H3>
          <P2>If you've bought Boxy Suite, to get started you need to:</P2>
          <StyledUl>
            <li>
              Access your <a href="https://account.boxysuite.com/">Boxy Suite account</a>
            </li>
            <li>
              Navigate to the <strong>Download page</strong>
            </li>
            <li>Download Boxy Suite</li>
            <li>
              You should have also got a "Welcome to Boxy Suite" email from us with the details of
              your account and a link to download the apps.
            </li>
          </StyledUl>
        </LayoutSection>
        <Footer legal />
      </LayoutWrapper>
    </>
  );
};

export default LifetimeDownloadPage;
